
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$unternehmervision-primary: mat.define-palette(mat.$indigo-palette);
$unternehmervision-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$unternehmervision-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$unternehmervision-theme: mat.define-light-theme((
  color: (
    primary: $unternehmervision-primary,
    accent: $unternehmervision-accent,
    warn: $unternehmervision-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($unternehmervision-theme);

@import './styles/roboto.scss';
@import './styles/opensans.scss';
@import '~material-design-icons/iconfont/material-icons.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.body {
  min-height: calc(100% - 100px);
  clear: both;
}
